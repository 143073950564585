import IngPage404 from '@/views/Ing404Page';
import IngBorrowerSignupPage from '@/views/IngBorrowerSignupPage';
import IngBorrowerSuccessPage from '@/views/IngBorrowerSuccessPage';
import IngBecomePartnerPage from '@/views/IngBecomePartnerPage';
import IngPartnerSubmissionPage from '@/views/IngPartnerSubmissionPage';
import IngPartnerSuccessPage from '@/views/IngPartnerSuccessPage';
import IngClientUIPage from '@/views/IngClientUIPage';
import IngClientUISuccessPage from '@/views/IngClientUISuccessPage';
import IngClientUIMaintenancePage from '@/views/IngClientUIMaintenancePage';
import IngClientUILogoutPage from '@/views/IngClientUILogoutPage';
import IngOfferUIPage from '@/views/IngOfferUIPage';
import IngOfferUILogoutPage from '@/views/IngOfferUILogoutPage';
import IngSignContractSuccessPage from '@/views/IngSignContractSuccessPage';
import IngSignContractFailurePage from '@/views/IngSignContractFailurePage';
import NewsletterSubscriptionPage from '@/views/NewsletterSubscriptionPage';
import NewsletterConfirmationPage from '@/views/NewsletterConfirmationPage';

import APPLICATION_CHANNELS from '@/constants/APPLICATION_CHANNELS';
import APPLICATION_PAGE from '@/constants/APPLICATION_PAGE';

// !*! --------------------------- INFO -------------------------- !*!
//
//    please refer the route object structure, before making any
//    modifications. Key-value pairs 'channel' & 'page' in 'meta'
//    are inserted using the 'Array.map' method.
//
// !*! ---------------- Route Object - Reference ----------------- !*!
//
//      {
//        path: '/url/path/to/page',
//        name: 'name-of-the-route',
//        component: ComponentPageName,
//        props: Boolean,
//        meta: { channel: CONSTANT.CHANNEL, page: CONSTANT.PAGE },
//      },
//
// !*! --------------------------- X ----------------------------- !*!

const webRoutes = [
  {
    path: '/',
    redirect: { name: 'web-firmenkredit' },
  },
  {
    path: '/web',
    redirect: { name: 'web-firmenkredit' },
  },
  {
    path: '/web/firmenkredit',
    name: 'web-firmenkredit',
    component: IngBorrowerSignupPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.ANTRAG,
    },
  },
  {
    path: '/web/firmenkredit/danke',
    name: 'web-firmenkredit-danke',
    component: IngBorrowerSuccessPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.ANTRAG,
    },
  },

  {
    path: '/web/firmenkredit/:opportunityId/vorschlag',
    props: true,
    name: 'web-firmenkredit-vorschlag',
    component: IngClientUIPage,
    meta: {
      page: APPLICATION_PAGE.CLIENT,
    },
  },
  {
    path: '/web/firmenkredit/:opportunityId/vorschlag/danke',
    name: 'web-firmenkredit-vorschlag-danke',
    component: IngClientUISuccessPage,
    meta: {
      page: APPLICATION_PAGE.CLIENT,
    },
  },
  {
    path: '/web/firmenkredit/:opportunityId/vorschlag/logout',
    name: 'web-firmenkredit-vorschlag-logout',
    component: IngClientUILogoutPage,
    meta: {
      page: APPLICATION_PAGE.CLIENT,
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance-page',
    component: IngClientUIMaintenancePage,
  },
  {
    path: '/web/firmenkredit/:loanRequestId/vertrag',
    props: true,
    name: 'web-firmenkredit-vertrag',
    component: IngOfferUIPage,
    meta: {
      page: APPLICATION_PAGE.OFFER,
    },
  },
  {
    path: '/web/firmenkredit/:loanRequestId/logout',
    props: true,
    name: 'web-firmenkredit-vertrag-logout',
    component: IngOfferUILogoutPage,
    meta: {
      page: APPLICATION_PAGE.OFFER,
    },
  },
  {
    path: '/web/firmenkredit/vertrag/danke',
    props: true,
    name: 'web-firmenkredit-vertrag-danke',
    component: IngSignContractSuccessPage,
    meta: {
      page: APPLICATION_PAGE.OFFER,
    },
  },
  {
    path: '/web/firmenkredit/vertrag/fehler',
    props: true,
    name: 'web-firmenkredit-vertrag-fehler',
    component: IngSignContractFailurePage,
    meta: {
      page: APPLICATION_PAGE.OFFER,
    },
  },
];

const partnerRoutes = [
  {
    path: '/partner',
    name: 'project-root',
    redirect: { name: 'partner-firmenkredit' },
  },
  {
    path: '/partner/firmenkredit',
    name: 'partner-firmenkredit',
    component: IngPartnerSubmissionPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.PARTNER,
    },
  },
  {
    path: '/partner/firmenkredit/danke',
    name: 'partner-firmenkredit-danke',
    component: IngPartnerSuccessPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.PARTNER,
    },
  },
  {
    path: '/ing-partner-werden',
    name: 'ing-partner-werden',
    component: IngBecomePartnerPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.PARTNER,
    },
  },
];

const subscriptionRoutes = [
  {
    path: '/anmeldung/newsletter',
    name: 'anmeldung-newsletter',
    component: NewsletterSubscriptionPage,
    props: true,
    meta: {},
  },
  {
    path: '/anmeldung/newsletter/bestaetigung',
    name: 'anmeldung-newsletter-bestaetigung',
    component: NewsletterConfirmationPage,
    props: true,
    meta: {},
  },
];

const notFoundRoute = [
  {
    path: '*',
    name: 'not-found',
    component: IngPage404,
    props: true,
  },
];

export default [
  ...webRoutes,
  ...partnerRoutes,
  ...subscriptionRoutes,
  ...notFoundRoute,
].map((route) => ({
  ...route,
  meta: { ...route.meta, channel: APPLICATION_CHANNELS.BRIDGE },
}));
